/* @import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap'); */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Cormorant;
  src: url('assets/font/Cormorant.ttf');
}

#root {
  margin-left: auto;
  margin-right: auto;
}

.titleFont1 {
  font-family: 'Cormorant', sans-serif;
}


body {

  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #FDFCF9;
  color: #2D2D2D;
}

div {
  line-height: 160% !important;
  letter-spacing: 0.32px;
}

.down-in {
  animation: downIn 0.3s both;
}

@keyframes downIn {
  from {
    transform: translate(0, 100%);
    opacity: 0;
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.hiddenScrollBar {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

.hiddenScrollBar::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}


input,
.input {
  border-radius: 10px;
  border-color: #14192A;
  border-style: solid;
  border-width: 1px;
  background-color: #070a12;
  color: white !important;
  font-weight: 700;
  padding: 8px;

}

.svg {
  fill: currentColor;
}

th,
td {
  text-align: left;
  padding: 8px;
}


.session1 {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  padding-left: 20px;
  padding-right: 20px;
}


.text-h1 {
  font-size: 40px;
  line-height: 43px;
}

.text-h1\.5 {
  font-size: 36px;
  line-height: 43px;
}

.text-h1\.75 {
  font-size: 33px;
  line-height: 40px;
}

.text-h2 {
  font-size: 26px;
  line-height: 31px;
}

.text-h2\.5 {
  font-size: 22px;
  line-height: 25px;
}

.text-h3 {
  font-size: 18px;
  line-height: 22px;
}

.text-h3\.5 {
  font-size: 16px;
  line-height: 19px;
}

.text-h4 {
  font-size: 14px;
  line-height: 16px;
}

.text-h5 {
  font-size: 12px;
  line-height: 14px;
}



@media screen and (min-width: 1024px) {

  .session1 {
    width: 100%;
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 120px;
    padding-right: 120px;
  }

  .text-h1 {
    font-size: 56px;
    line-height: 68px;
  }

  .text-h1\.5 {
    font-size: 33px;
    line-height: 40px;
  }

  .text-h1\.75 {
    font-size: 31px;
    line-height: 35px;
  }

  .text-h3 {
    font-size: 22px;
    line-height: 25px;
  }

  .text-h3\.5 {
    font-size: 18px;
    line-height: 22px;
  }

  .text-h4 {
    font-size: 16px;
    line-height: 18px;
  }
}

.homeWrapper {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 100vh;
}

.homeContent {
  scroll-snap-align: start;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;

}


.textline2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn1 {
  background-color: #212653;
  border-radius: 9999px;
  padding: 10px 4px;
  min-width: 80px;
  font-weight: 600;
  color: #FFFFFF;
  font-size: 14px;
}

.btn2 {
  background-color: #FFFFFF;
  border-style: solid;
  border-width: 2px;
  border-color: #47517C;
  border-radius: 9999px;
  padding: 10px 4px;
  min-width: 80px;
  font-weight: 600;
  color: #47517C;
  font-size: 14px;
}

h3 {
  color: #3E54B0;
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;

}

.gradiBorder {
  background: linear-gradient(90deg, #CA9849 -0.08%, #FFF4AF 85.1%);
}

*:focus {
  outline: none;
}

.boxShadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.specFont {
  font-family: 'Cormorant', sans-serif;
}


.opacity-54 {
  opacity: 0.54;
}

.ratio-1\/1 {
  width: 100%;
  padding-top: 100%;
  /*  Aspect Ratio */
  position: relative;
}

.ratio-16\/9 {
  width: 100%;
  padding-top: 56.25%;
  /*  Aspect Ratio */
  position: relative;
}

.ratio-in {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}



.Line1 {
  background: linear-gradient(90deg, #D49F4A 0%, #FFF4AF 100%);
}


.bg1 {
  position: relative;
}

.bg1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("assets/bg.jpg");
  background-repeat: repeat;
  background-size: 100% 100%;
  /* background-position-y: 50%; */
  /* background-position-x: 100%; */
}


.menubg {
  position: relative;
}

.menubg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("assets/menubg.png");  */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* background-position-y: 50%; */
  /* background-position-x: 100%; */
}



.appbg {
  position: relative;
}

.appbg::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("assets/appbg.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  /* opacity: 0.25;
  filter: brightness(0.95); */
  /* background-position-y: 50%; */
  /* background-position-x: 100%; */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.appWidth {
  width: 90%;
  max-width: 1470px;
  margin-left: auto;
  margin-right: auto;
}


.textShadow {
  font-family: 'Oxanium-SemiBold', sans-serif;
  color: #FFFFFF;
  text-shadow: 0px -3px 8px rgba(250, 152, 25, 0.7), 0px 3px 8px rgba(216, 203, 22, 0.7);
}

.gradiBtn {
  background: linear-gradient(87.91deg, #F117A7 7.35%, #7010E9 93.68%);
  border-radius: 9999px;
  color: #FFFFFF;
  font-weight: 600;
}

.blingBtn {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.5px;
  fill: #FFFFFF;
  color: #FFFFFF;
  background-color: #EB6E54;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #EB6E54;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 10px 20px 0px rgba(225, 69, 33, 0.28);

  transition: all .45s;
  transition-property: all;
  transition-duration: 0.45s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.blingBtn:hover {
  background-color: rgb(224, 93, 66);
}


.anim {
  transition: all 0.2s ease-out;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}